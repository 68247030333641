
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "contacts-search",
  components: {},
  setup() {
    const store = useStore();
    const loadingContacts = ref(0);
    const contacts = ref<any[]>([]);
    const currentPage = ref(1);
    const search = ref("");
    let savedSearch = "";

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        return;
      }
    );

    const refreshContacts = async function () {
      if (loadingContacts.value > 0) return;
      loadingContacts.value++;
      try {
        let searchComponent = "";
        if (savedSearch !== "") {
          searchComponent = "&email=" + search.value;
        }

        let response = await ApiService.get(
          "/api/v1/contacts?page=" + currentPage.value + searchComponent,
          "",
          {
            headers: {
              Accept: "application/ld+json",
            },
          }
        );
        let prevResults = contacts.value["hydra:member"];
        if (prevResults === undefined) prevResults = [];
        prevResults.push(...response.data["hydra:member"]);
        contacts.value = response.data;
        contacts.value["hydra:member"] = prevResults;
      } catch (e) {
        console.log(e);
      } finally {
        loadingContacts.value--;
      }
    };

    const checkScroll = function (e) {
      let element = e.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        if (contacts.value["hydra:view"]["hydra:next"] !== undefined) {
          currentPage.value++;
        }
      }
    };

    watch(
      () => store.state.AuthModule.selectedLocation.name,
      () => {
        refreshContacts();
      }
    );
    watch(
      () => currentPage.value,
      () => {
        refreshContacts();
      }
    );

    const applySearch = function () {
      currentPage.value = 1;
      contacts.value = [];
      savedSearch = search.value;
      refreshContacts();
    };

    onMounted(() => {
      setCurrentPageTitle("List Contacts");
      refreshContacts();
    });
    return {
      store,
      contacts,
      loadingContacts,
      currentPage,
      checkScroll,
      search,
      applySearch,
    };
  },
});
